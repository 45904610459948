import React from 'react';
import { Link } from "gatsby"
import ButtonLink from '../ui/Buttons/ButtonLink';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import HorizontalScroll from '../ui/HorizontalScroll/HorizontalScroll';
import Hero from '../ui/Hero/Hero';
import * as styles from './ProductReference.module.scss';
import classnames from 'classnames';
import { IBlockProductReference } from './IBlocks';


type Props = {
    className?: any;
}

export default (props: Props & IBlockProductReference) => {
    const {references, pathToProductList, title, text, className} = props;

    return (
        <Hero 
                color="dark-blue"
                hasRoundedCorner={true}
                className={className}
            >
            <div className="columns is-multiline">
                    {(title && pathToProductList) && <div className="column is-full-tablet is-one-quarter-desktop" style={{display: "flex", alignItems: "top"}}>
                        <div className="content">
                                <h3 className="title is-3">{title}</h3>
                                <p>{text}</p>
                                <span  className="is-hidden-touch"><ButtonLink to={"/" + pathToProductList} >Zur Produktübersicht</ButtonLink></span>
                            </div>
                    </div>}

                    <div className="column">
                        <HorizontalScroll>
                            <div className="columns">
                                {references.map((node) => {
                                            const to = "/produkte/" + node.url;
                                            return (
                                                <div className="column" key={to}>
                                                    <Link to={to}>
                                                        <GatsbyImage
                                                            image={getImage(node.listenbild.src)}
                                                            alt={node.listenbild.alt}
                                                            title={node.listenbild.alt}
                                                            style={{borderBottomRightRadius: 50}} 
                                                        />
                                                        <h3 className={classnames(["title", "is-4", styles.title])}>{node.name}</h3>
                                                    </Link>
                                                    <p className={styles.p}>{node.kurzbeschreibung}</p>
                                                    
                                                
                                                    <ButtonLink to={to} variation={"minimal"}>zum Produkt</ButtonLink>
                                                </div>
                                            );
                                })}
                                </div>
                        </HorizontalScroll>
                    </div>

                    <div className="column is-full is-hidden-desktop">
                        <ButtonLink to={"/" + pathToProductList} >Zur Produktübersicht</ButtonLink>
                    </div>
                    
                </div>
        </Hero>
    );
    

}