import React from 'react';
import Layout from '../components/Layout';
import Hero from '../components/ui/Hero/Hero';
import { CartContext, CartContact } from '../components/Cart/CartContext';
import Section from '../components/ui/Section/Section';
import ButtonLink from '../components/ui/Buttons/ButtonLink';
import CartItem from '../components/Cart/CartItem';
import * as styles from './anfragen.module.scss';
import classnames from 'classnames';
import ButtonAction from '../components/ui/Buttons/ButtonAction';
import {object, string, boolean} from 'yup';
import Axios from 'axios';
import { Link,  useStaticQuery, graphql } from 'gatsby';
import ProductReference from '../components/blocks/ProductReference';
import {shuffle} from 'lodash';
import useGdprIsAllowed from '../components/Gdpr/useGdprIsAllowed';
import ErrorMessage from '../components/ErrorMessage';

export default function Anfragen () {

    // Hier die Produkte verewalten, die unten zufällig erscheinen werden.
    const data = useStaticQuery(graphql`{
  productReferences: allProdukteHJson(
    filter: {name: {in: ["Holz Schild Rund", "Polo Frauen", "Aufkleber Standard", "Alu-Verbund Schild Sprechblase", "Holz Schild Dreieck"]}}
  ) {
    edges {
      node {
        url
        kurzbeschreibung
        listenbild {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            name
          }
        }
      }
    }
  }
}
`);

    let productReferences: Array<any> = data.productReferences.edges;
    React.useEffect(() => {
        productReferences = shuffle(data.productReferences.edges);
    });
    const trackingAllowed = useGdprIsAllowed("Analyse");

    const validation = object({
        name: string().required("Wir benötigen Ihren Namen um Rückfragen zuordnen zu können."),
        email: string().required("Wird benötigt um mit Ihnen das Angebot zu schicken.").email("Bitte eine korrekte E-Mail Adresse eingeben!"),
        phone: string().notRequired(),
        message: string().notRequired(),
        dataprivacy: boolean().oneOf([true],"Bitte die Datenschutzbestimmungen akzeptieren."),
    });


    const {products, contact, dispatch} = React.useContext(CartContext);

    const [fieldErrors, setFieldErrors] = React.useState<CartContact>({name: "", email: "", phone: "", message: "", dataprivacy: false});
    const [sendData, setSendData] = React.useState<"open"|"sending"|"done">("open");

    const [errorMessage, setErrorMessage] = React.useState<React.ReactElement>(null);

    const onBlur = (e) => {
        validate(e.target.name);
    }


    const onChange = (e) => {
        const data = {};
        data[e.target.name] = (e.target.type === 'checkbox') ? !contact[e.target.name] : e.target.value;
        dispatch({type: "UPDATE_CONTACT",data});
        validate(e.target.name, true);
    }

    const validate = (path?: string, silent?: boolean) => {
        try {
            if (path) {
                validation.validateSyncAt(path, contact);
            } else {
                validation.validateSync(contact);
            }
            setFieldErrors({name: "", phone: "", email: "", message: "", dataprivacy: false});
            return true;
        } catch (e) {
            if (!silent) {
                let newValues: CartContact = {name: "", phone: "", email: "", message: "", dataprivacy: false,};
                newValues[e.path] = e.message;
                setFieldErrors(newValues);
                console.error("FORM ERROS", newValues);
            }
            
            return false;
        }
    }

    const submit = async () => {

        if (validate()) {
            setSendData("sending");
            try {
                const result = await Axios.post(process.env.API_URL + 'anfragen', {
                    contact,
                    products,
                });

                if (result.status !== 200) {
                    setSendData("open");
                    return;
                }
            } catch (e) {
                setSendData("open");
                setErrorMessage(<ErrorMessage title="Anfrage Formular absenden" message={e.message} close={() => { setErrorMessage(null)}} send={true} error={e} />);
                return;
            }

             // Track purchased order
             // track new products
            if (trackingAllowed && _paq) {
                for (const cartItem of products) {
                    _paq.push(['addEcommerceItem',
                        cartItem.product.url, // (required) SKU: Product unique identifier
                        cartItem.product.name, // (optional) Product name
                        [cartItem.product.produktTyp], // (optional) Product category, string or array of up to 5 categories
                        cartItem.qty // (optional, default to 1) Product quantity
                    ]);
                }

                _paq.push(['trackEcommerceOrder',
                    "A" + Date.now(), // (required) Unique Order ID
                    30 * products.length, // (required) Order Revenue grand total (includes tax, shipping, and subtracted discount)
                ]);
               
                _paq.push(['setCustomUrl', "/anfragen"])
                _paq.push(['setDocumentTitle', "Anfrage abgeschickt"])
                _paq.push(['trackPageView'])
            } 

            setSendData("done");
            dispatch({type: "CLEAR"});
            
            setTimeout(() => {
                    window.scrollTo({top: 0, behavior: 'smooth', });
            }, 100);

        } else {
            setSendData("open");
        }
        
    }

    return <div className={styles.background}>
            <Layout
            crumbs={[
                { label: "Home", url: "" },
                { label: "Anfragen", url: "anfragen" },
            ]}
            hideContact={true}
            hasWave={false}
            disableMiniCart={true}
        >
            
                {/* Success mesage */}
                {sendData === "done" && <Hero
                    title={"Vielen Dank"} 
                    subtitle={"Wir werden Ihre Anfrage schnellstmöglich bearbeiten und Ihnen ein Angebot per E-Mail zuschicken. Sollten Rückfragen auftreten, die wir für das Angebot benötigen, dann werden wir Sie zuvor kontaktieren."}
                    className={classnames([styles.firstHero, "highlight"])}
                >
                    <div className="columns">
                        <div className="column is-10-desktop is-offset-1-desktop">
                            <div className="content">
                            <p>
                                Wir haben Ihnen auch eine E-Mail mit allen Daten Ihrer Anfrage zugesendet.
                            </p>
                            <ButtonLink to="/produkte" onClick={() => { setSendData("open");}}>Weiter zu unseren Produkten</ButtonLink>
                        </div>
                        </div>
                    </div>
                  
                </Hero>}

                {/* Cart like page behaviour */}
                {sendData !== "done" && <><Hero
                    title={"Fragen Sie uns An!"} 
                    subtitle={"Um Ihre Wünsche zu erfüllen, arbeiten wir sehr gerne mit Ihnen direkt zusammen. Wir freuen uns von Ihnen zu hören!"}
                    hasWaveBottom={["mobile"]}
                    color="dark"
                    colorDesktop="light"
                    waveBottomColor="light"
                    className={styles.firstHero}
                />
                    <Section size="small" className={styles.section}>

                        {/* Product list */}
                        <div className={styles.cartItemsList}>
                            {products.length > 0 && <>
                                {products.map((product, index) => (
                                    <React.Fragment key={product.product.name + "-" + JSON.stringify(product.configuration)}>
                                        <CartItem product={product} productIndex={index} />
                                    </React.Fragment>
                                ))} 

                                <div className="content">
                                    <p>
                                        Erhalten Sie ein <b>unverbindliches Angebot</b> innerhalb <b>eines Werktages</b> von uns per E-Mail. 
                                        Darin enthalten sind <b>Preise</b> für Ihre ausgewählten Produkte sowie Lieferinformationen. 
                                        Wir versenden je nach Größe via <b>DHL/UPS</b> oder <b>Spedition</b>. Selbstabholung ist auch möglich.
                                    </p>
                                </div>
                            </>}
                        </div>


                        {/* Contact form */}
                        <div className={classnames(["content", styles.contact])}>
                            <h2 className="title">Wie können wir Sie erreichen?</h2>
                            <p>
                                Teilen Sie uns alle wissenswerten Informationen zu Ihren Vorstellungen und Wünschen mit und wir setzen uns dann direkt mit Ihnen in Kontakt!
                            </p>
                            <div className="columns">
                                <div className="column is-half">

                                    <div className="field">
                                        <div className="control">
                                            <input className={classnames(["input", (fieldErrors.name ? "is-danger": "")])}  placeholder="Wie lautet Ihr Name?" name="name" value={contact.name} onChange={onChange} onBlur={onBlur}  />
                                        </div>
                                        {fieldErrors.name && <p className={classnames(["help", "is-danger", styles.errorMessage])}>{fieldErrors.name}</p>}
                                    </div>
                                    
                                    <div className="field">
                                        <div className="control">
                                            <input className={classnames(["input", (fieldErrors.email ? "is-danger": "")])} placeholder="Ihre E-Mail Adresse" type="email" name="email" value={contact.email} onChange={onChange}  onBlur={onBlur} />
                                        </div>
                                        {fieldErrors.email && <p className={classnames(["help", "is-danger", styles.errorMessage])}>{fieldErrors.email}</p>}
                                    </div>

                                    <div className="field">
                                        <div className="control">
                                            <input className={classnames(["input", (fieldErrors.phone ? "is-danger": "")])}  placeholder="Ihre Telefonnummer" name="phone" value={contact.phone} onChange={onChange} onBlur={onBlur}  />
                                        </div>
                                        {fieldErrors.phone && <p className={classnames(["help", "is-danger", styles.errorMessage])}>{fieldErrors.phone}</p>}
                                    </div>

                                </div>

                                <div className="column">
                                    <textarea 
                                        value={contact.message}
                                        placeholder="Teilen Sie uns alle wissenswerten Informationen zu Ihren Vorstellungen und Wünschen mit und wir setzen uns dann direkt mit Ihnen in Kontakt." 
                                        className={classnames(["textarea", (fieldErrors.message? "is-danger": "")])} 
                                        rows={5}
                                        name="message"
                                        onChange={onChange}
                                        onBlur={onBlur} 
                                        
                                    />
                                </div>
                            </div>

                            <div className="columns">
                                <div className="column">
                                    <label className={classnames([(fieldErrors.dataprivacy) ? "has-text-danger" : "", "checkbox"])}>
                                        <input className={fieldErrors.dataprivacy ? "is-danger" : ""} type="checkbox" name="dataprivacy" checked={contact.dataprivacy} onChange={onChange} />
                                        {fieldErrors.dataprivacy} Die abgesendeten Daten werden nur zum Zweck der Bearbeitung Ihres Anliegens verarbeitet. Weitere Informationen finden Sie in unserer <Link to="/datenschutz">Datenschutzerklärung</Link>.
                                    </label>
                                </div>
                                <div className="column has-text-right">
                                {errorMessage}
                                    <ButtonAction icon="send" onClick={submit} className={(sendData === "sending") ? "is-loading": ""}>Kontaktieren Sie uns</ButtonAction>
                                </div>
                            </div>
                        </div>

                    </Section>

                    <ProductReference
                        references={[
                            productReferences[0].node,
                            productReferences[1].node,
                            productReferences[2].node,
                        ]}
                        title="Eine Vielzahl an Produkten"
                        text="Fügen Sie unsere Produkte Ihrer Anfrage hinzu. Damit wissen wir genauer was Sie benötigen. Hier drei unserer Bestseller."
                        pathToProductList="produkte"
                    />
                </>}
        </Layout>
    </div>
}