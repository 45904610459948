import React from 'react';
import { Link } from 'gatsby';
import Axios from 'axios';
import ErrorIllustration from "../assets/error.svg";
import * as styles from './ErrorMessage.module.scss';
import classnames from 'classnames';


type Props = {
    title: string;
    message?: string;
    close: () => void;
    error: any;
    send?: boolean;
}

export default  function ErrorMessage (props: Props) {
    const { title, message, close, error, send} = props;

    if (send) {
        Axios.post(process.env.API_URL + 'log', {
            title,
            message: JSON.stringify(error),
        });
    }


    return <div className={classnames(["notification is-danger has-text-left ", styles.wrapper])}>
    <button className="delete" onClick={close}></button>
        <p className="has-text-weight-bold">Ohh, da hat etwas nicht geklappt...</p>
        <p>
            Das tut uns sehr Leid. Leider konnten wir die Aktion nicht ausführen. Wir sind informiert und kümmern uns sofort darurm!
            Melden Sie sich doch direkt bei uns über unsere <Link to="/kontakt">Kontaktmöglichkeiten</Link>. Bitte Entschuldigen Sie die Umstände!
        </p>
        {message && <p className="is-size-7 is-italic">Datenausgabe: {message}</p>}
        <img src={ErrorIllustration} alt="Oh sorry!" className={classnames([styles.image, "is-hidden-mobile"])} />
</div>;


}