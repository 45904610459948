
import React from 'react';
import { CartContext, CartPosition } from '../../components/Cart/CartContext';
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./CartItem.module.scss";
import FileUpload from '../ProductWidget/FileUpload';
import classnames from 'classnames';

import DeleteIcon from '../../assets/delete.icon.svg';
import AddIcon from '../../assets/add.icon.svg';
import { Link } from 'gatsby';
import ProductConfiguration from '../ProductWidget/ProductConfiguration';

type Props = {
    productIndex: number;
    product: CartPosition;
}

export default function CartItem (props: Props) {
    const {product, productIndex} = props;
    const {dispatch} = React.useContext(CartContext);
    const [messageDialog, setMessageDialog] = React.useState<boolean>(product.message ? true : false);
    const [deleted, setDeleted] = React.useState<boolean>(false);
    const [deletedPermanent, setDeletedPermanent] = React.useState<boolean>(false);
    const stopDeletion = React.useRef<boolean>(false);

    const onTextareaChange = (e) => {
        dispatch({type: "UPDATE_PRODUCT", index: productIndex, data: {message: e.target.value}});
    }

    const onQuantityChange = (e) => {
        dispatch({type: "UPDATE_PRODUCT", index: productIndex, data: {qty: e.target.value}});
    }

    const removeItem = () => {
        setDeleted(true);
        setTimeout(() => {
                if (!stopDeletion.current) {
                    setDeletedPermanent(true);
                    // wait for animation end then remove product permanent
                    setTimeout(() => {
                        dispatch({type: "REMOVE_PRODUCT", position: product});
                    }, 500)
                    
                } else {
                    stopDeletion.current = false;
                }
        }, 3000);
    }

    const undoRemoveItem = (e) => {
        e.preventDefault();
        setDeleted(false);
        stopDeletion.current = true;
    }

    const onFileUploadChange = (data) => { 
        dispatch({type: "UPDATE_PRODUCT", index: productIndex, data: {configuration: {...product.configuration, files: data}}}); 
    };

    const onConfigurationChange = (data) => { 
        dispatch({type: "UPDATE_PRODUCT", index: productIndex, data: {configuration: {...product.configuration, ...data}}}); 
    };

    if (!product) {
        return null;
    }

    return (
        <div className={classnames([styles.wrapper, deletedPermanent ? styles.wrapperDeleted : ""])}>

                        <div className={styles.product}>

                            {product.product.listenbild.src && <div className={styles.imageWrapper}>
                                <GatsbyImage image={product.product.listenbild.src.childImageSharp.gatsbyImageData} alt={product.product.listenbild.alt} />
                            </div>}

                            <div className={styles.textWrapper}>
                                
                                <div className="columns is-multiline">
                                        <div className="column is-full">

                                            {product.product.url && <Link to={"/produkte/" + product.product.url}><h3 className={styles.productTitle}>{product.product.name}</h3></Link> }
                                            {!product.product.url && <h3 className={styles.productTitle}>{product.product.name}</h3> }

                                        </div>
                                    <div className="column">
                                        {/* show configuration */}
                                        <ProductConfiguration values={product.configuration} product={product.product} onChange={onConfigurationChange} />

                                        {/* file Upload */}
                                        {product.configuration.files && <div className={styles.fileUploadWrapper}>
                                            <FileUpload value={product.configuration.files} onChange={onFileUploadChange}  />
                                        </div>}

                                        {/* notes for item */}
                                        {!product.message && !messageDialog && <a className={classnames(["has-icon", styles.addNoteButton])} onClick={() => { setMessageDialog(true); }} >
                                            <span className="icon svg">
                                                <AddIcon />
                                            </span>
                                            <span>Notizen hinzufügen</span>
                                        </a>}

                                        {(messageDialog || product.message) && <div>
                                            Notiz:<br />
                                            <textarea key="notes" value={product.message ? product.message : ""} rows={2} className="textarea" onChange={onTextareaChange} />
                                        </div>}

                                    </div>

                                    <div className={classnames(["column", "is-2", styles.sideAction])}>

                                        <div className="field has-addons">
                                            <p className="control is-expanded">
                                                <input className="input" type="text" onChange={onQuantityChange} value={product.qty} />
                                            </p>
                                            <p className="control">
                                                <a className="button is-static">
                                                    Stk
                                                </a>
                                            </p>
                                        </div>
                                        

                                        <div className={styles.deleteWrapper}>
                                            <a className={classnames([styles.deleteButton, styles.button])} onClick={removeItem} >
                                                <span className="icon svg">
                                                    <DeleteIcon />
                                                </span>
                                                <span>Entfernen</span>
                                            </a>
                                        </div>
                                        
                                    </div>
                                </div>




                                


                            </div>
                        </div>

                        <div className={classnames([styles.undoDeletion, deleted ? styles.undoDeletionActive : ""])}>
                                {deleted && <div className="content">
                                        <span className="title">Der Artikel wurde erfolgreich entfernt.</span><br />
                                        <a href="#" onClick={undoRemoveItem}>Rückgängig machen</a>
                                </div>}
                        </div>
                    </div>
    );
}