import React from 'react';
import classnames from 'classnames';
import * as styles from './ProductConfiguration.module.scss';
import { filterToLabel } from '../../utils/FilterHelper';
import Portal from '../Portal';
import IProdukt, { IProduktMinimal } from '../../interfaces/IProdukt';
import ProductWidget, { getValidatorPerFilterTyp } from './ProductWidget';
import { ProductWidgetContextProvider, IProductWidgetState, ProductWidgetContext } from './ProductWidgetContext';
import EditIcon from '../../assets/edit.icon.svg';

// fake namespace to use product widget
const namespace: string = "product";

type Props = {
    onChange: (data: any) => void;
    values: {[key: string]: any};
    product: IProduktMinimal;
}

export default function ProductConfiguration (props: Props) {
    const {values, product, onChange} = props;
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);

    const availableFilterValues = Object.keys(values).filter((key) => {
        switch(key){
            case "files":
            case "typ":
            case "menge":
            case "message":
                return false;
        }
        return true;
    });

    const avialableFilters = product.filters.filter((value) => {
        switch (value.typ) {
            case "files":
            case "typ":
            case "menge":
            case "message":
            case "material":
            case "form":
                return false;
        }
        return true;
    });

    let contextInitialValue: IProductWidgetState = {
        forms: {
            product: {
                values: {},
                state: "open",
            }
        },
        dispatch: () => {},
    }

    // add values to contextInitialValue
    avialableFilters.forEach((filter) => {
        contextInitialValue.forms[namespace].values[filter.typ] =  {
            error: "",
            validationFunc: getValidatorPerFilterTyp(filter.typ, filter.widgetType),
            value: values[filter.typ],
        }
    });

    const onSubmit = (data) => {
        setModalOpen(false);
        onChange(data);
    }

    if (availableFilterValues.length <= 0) {
        return null;
    }

    return <div>

    <p>
        {/* Extract all available filters */}
        {availableFilterValues.map((key, index, array) => {
            let value = values[key];
            let label = filterToLabel(key) ? filterToLabel(key) + ": " : null;
            let divider = index + 1 < array.length ? " | " : "";

            if (key === "size") {
                value = value.length + " x " + value.width + " cm";
            }

            return  <span key={index}>
                <span style={{whiteSpace: "nowrap"}}>{label} {value}</span>  {divider}
            </span>
        })}
        
        <span className={classnames([styles.changeButton])} onClick={() => { setModalOpen(true); }}>
            <i className={classnames([ styles.icon])} ><EditIcon type="add" /></i>
            Anpassen
        </span>
    </p>

            
    <Portal>
        <div className={classnames(["modal", modalOpen ? "is-active" : "", styles.modal])}>
            <div className="modal-background"  onClick={() => { setModalOpen(false); }}></div>
            <div className="modal-card">
                <ProductWidgetContextProvider value={contextInitialValue}>
                    <header className="modal-card-head">
                        <p className="modal-card-title" style={{marginBottom: 0}}>Produkt ändern</p>
                        <button className="delete" aria-label="close" onClick={() => { setModalOpen(false); }}></button>
                    </header>
                    <section className={classnames(["modal-card-body", styles.modalBody])}>
                        
                                    <ProductWidget product={{...product, filters: avialableFilters, productWidgetKey: "product"} as IProdukt}  hideAnfrageButton={true} />
                            
                    </section>
                    <footer className={classnames(["modal-card-foot"])}>
                        <SubmitChanges onSubmit={onSubmit} />
                    </footer>
                </ProductWidgetContextProvider>
            </div>
        </div>



        
        </Portal>

  </div>
}

type SubmitChangesProps = {
    onSubmit: (data: {[key: string]: any}) => void,

}

const SubmitChanges = (props: SubmitChangesProps) => {
    const widgetState = React.useContext(ProductWidgetContext);
    const {forms, dispatch} = widgetState;
    const widgetData = forms[namespace]

    const submit = () => {
        let configuration: {[key: string]: any} = {};

        let valid: boolean = true;
        for (const fieldName in widgetData.values) {
            // Validate:
            try {
                widgetData.values[fieldName].validationFunc.validateSync(widgetData.values[fieldName].value);
                if (widgetData.values[fieldName].error) {
                    dispatch({type: "CLEAR_ERROR", name: fieldName, namespace,});
                }
            } catch (e) {
                valid = false;
                dispatch({type: "SET_ERROR", name: fieldName, namespace, message: e.message})
            }
            configuration[fieldName] = widgetData.values[fieldName].value;
        }
        delete configuration.product;
        delete configuration.menge;

        if (valid) {
            props.onSubmit(configuration);
        } 

    }

    return <button className="button" onClick={submit}>Übernehmen</button>
}