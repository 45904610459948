// extracted by mini-css-extract-plugin
export var wrapper = "CartItem-module--wrapper--scQeF";
export var product = "CartItem-module--product--U2Hi5";
export var imageWrapper = "CartItem-module--imageWrapper--Oiio9";
export var textWrapper = "CartItem-module--textWrapper--2XvLi";
export var productTitle = "CartItem-module--productTitle--N8SjG";
export var button = "CartItem-module--button--3Ag9q";
export var deleteButton = "CartItem-module--deleteButton--2ic2Z";
export var addNoteButton = "CartItem-module--addNoteButton--1YVog";
export var fileUploadWrapper = "CartItem-module--fileUploadWrapper--1yRgy";
export var wrapperDeleted = "CartItem-module--wrapperDeleted--3_oOL";
export var undoDeletion = "CartItem-module--undoDeletion--25ULc";
export var undoDeletionActive = "CartItem-module--undoDeletionActive--3p2G6";
export var deleteWrapper = "CartItem-module--deleteWrapper--YxccC";
export var sideAction = "CartItem-module--sideAction--2StqI";
export var spinAround = "CartItem-module--spinAround--32GEI";